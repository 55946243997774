import React from "react";
import { Layout, Seo } from "components";
import { Row, Col } from "react-grid-system";
import HCPIsi from "../codes/components/layout/layout-blocks/isi/isi-contents/HcpIsi";
// import page style
import "./safety-information-indication.scss";
import { Link } from "gatsby";
import SideNav from "../codes/components/side-nav";

const SafetyInformationIndication = () => {
    return (
        <Layout indication="patient" className="page-hcp-isi" noShowStickyIsi>
            <Seo
                pageTitle=" Safety Information & Indication"
                pageDescription="See Butrans Transdermal System Full Prescribing Info, Safety Info and Boxed Warning."
            />
            <div className="body-content-container">
                <SideNav pageId="safety-information-indication" />
                <div className="body-content gutter-all">
                    <Row>
                        <Col xs={12}>
                            <h1>IMPORTANT SAFETY INFORMATION</h1>
                            <div className="isi">
                                <div className="isi-content-wrapper">
                                    <div className="isi-content">
                                        <HCPIsi />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </Layout>
    );
};

export default SafetyInformationIndication;
